exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-posts-posts-8-raisons-pour-perdre-du-poids-mdx": () => import("./../../../src/posts/posts/8-raisons-pour-perdre-du-poids.mdx" /* webpackChunkName: "component---src-posts-posts-8-raisons-pour-perdre-du-poids-mdx" */),
  "component---src-posts-posts-comment-perdre-kilos-emotionnels-mdx": () => import("./../../../src/posts/posts/comment-perdre-kilos-emotionnels.mdx" /* webpackChunkName: "component---src-posts-posts-comment-perdre-kilos-emotionnels-mdx" */),
  "component---src-posts-posts-comment-perdre-kilos-grossesse-mdx": () => import("./../../../src/posts/posts/comment-perdre-kilos-grossesse.mdx" /* webpackChunkName: "component---src-posts-posts-comment-perdre-kilos-grossesse-mdx" */),
  "component---src-posts-posts-comment-perdre-kilos-hormonaux-mdx": () => import("./../../../src/posts/posts/comment-perdre-kilos-hormonaux.mdx" /* webpackChunkName: "component---src-posts-posts-comment-perdre-kilos-hormonaux-mdx" */),
  "component---src-posts-posts-comment-perdre-kilos-menopause-mdx": () => import("./../../../src/posts/posts/comment-perdre-kilos-menopause.mdx" /* webpackChunkName: "component---src-posts-posts-comment-perdre-kilos-menopause-mdx" */),
  "component---src-posts-posts-comment-perdre-poids-durablement-mdx": () => import("./../../../src/posts/posts/comment-perdre-poids-durablement.mdx" /* webpackChunkName: "component---src-posts-posts-comment-perdre-poids-durablement-mdx" */),
  "component---src-posts-posts-ou-perd-on-du-poids-en-premier-mdx": () => import("./../../../src/posts/posts/ou-perd-on-du-poids-en-premier.mdx" /* webpackChunkName: "component---src-posts-posts-ou-perd-on-du-poids-en-premier-mdx" */),
  "component---src-templates-category-page-tsx": () => import("./../../../src/templates/CategoryPage.tsx" /* webpackChunkName: "component---src-templates-category-page-tsx" */)
}

